
// Headings
h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	margin: 0 0 $heading-margin;
	color: $color-title;
	font-family: $font-family-title;
	font-weight: $font-normal;
	line-height: 1.5;
}

h1,.h1 { font-size: $h1; letter-spacing: -1px; font-weight: $font-bold; }
h2,.h2 { font-size: $h2; letter-spacing: -1px; }
h3,.h3 { font-size: $h3; }
h4,.h4 { font-size: $h4; }
h5,.h5 { font-size: $h5; }
h6,.h6 { font-size: $h6; }

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	color: $color-darkgrey;
}
