
//==========  Colors  ==========//

// set our colour variables
// avoid using obvious declarative names as the colours may not always match the name
// e.g, $colour-orange
// 
// we also create lighter and darker tones for use with hover states, etc.

$color-primary: 			#233582; 						// the brand colour
$color-primary-light:		#007bc2;	// the brand colour - lighter
$color-primary-dark:		darken($color-primary, 5%);		// the brand colour - darker
$color-primary-wash:		#e7ebf1;		// the brand colour - darker

$color-secondary:  		#34a4b5;
$color-secondary-light:	lighten($color-secondary, 5%);
$color-secondary-dark:		darken($color-secondary, 5%);

$color-tertiary: 			#724395; 
$color-tertiary-light:		lighten($color-tertiary, 5%);
$color-tertiary-dark:		darken($color-tertiary, 5%);

$color-quaternary: 				#579e1e; 						// the accent colour
$color-quaternary-light:		lighten($color-quaternary, 5%);	// the accent colour - lighter
$color-quaternary-dark:			#579e1e;	// the accent colour - darker

$color-individuals: 			$color-quaternary;
$color-individuals-light: 		$color-quaternary-light;
$color-individuals-dark: 		$color-quaternary-dark;

$color-business: 				$color-primary-light;
$color-business-light: 			lighten($color-business, 5%);
$color-business-dark: 			darken($color-business, 5%);

$color-hr: #c21d23;


//==========  Tones  ==========//

// set grey tones variables
// these will typically be used for borders and form inputs
// again, we also create lighter and darker tones for use with hover states, etc.

$color-lightgrey:		#dce1e5;							// a default light grey
$color-lightgrey-light:	lighten($color-lightgrey, 5%);	// a default light grey - lighter
$color-lightgrey-dark:	darken($color-lightgrey, 5%);	// a default light grey - darker

$color-midgrey:			#a8b3bd;	 						// a default mid grey
$color-midgrey-light:	lighten($color-midgrey, 5%);	// a default mid grey - lighter
$color-midgrey-dark:	darken($color-midgrey, 5%);		// a default mid grey - darker

$color-darkgrey:		#4f7297;						// a default dark grey
$color-darkgrey-light:	#8996a0;	// a default dark grey - lighter
$color-darkgrey-dark:	darken($color-darkgrey, 5%);	// a default dark grey - darker

$color-white: 			#fff;							// a default white
$color-black: 			#111;							// a default black


//==========  Text colors  ==========//

// set our default text colours for titles, paragraphs, etc.

$color-text:	#636c74;								// body text colour
$color-sub:		$color-text;								// sub and intro text colour
$color-title:	$color-primary;							// heading text colour
