
// Content styles
.block-text,
.biography {
	line-height: 1.9;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		a {
			color: $color-secondary;

			&:hover {
				text-decoration: underline;
			}
			
		}

		&.intro {
			padding: 0 0 $paragraph-margin;
			border-bottom: $border;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h4;
			
			@media #{$mobileXL} {
				font-size: $h3;
			}
		}
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;

		&.search-tabs,
		&.search-results {
			@extend %reset-ul;
		}

		&.search-tabs {
			li {
				margin-bottom: 0;
			}
		}

	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
	}

	ul, ol {

		li {
	        margin-bottom: ($paragraph-margin * 1/3);

	        &:last-child {
	            margin-bottom: 0;
	        }
	    }
	}
	
}

.info-text {
	font-size: $font-size-body - 0.2;

	ul {
		li {
			margin-bottom: 0;
		}

		ul {
			margin: 0;
		}
	}
}
