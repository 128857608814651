
//==========  Import modules  ==========//

// import our global modules
@import '../modules/global/config/__index';
@import '../modules/global/utils/__index';
@import '../modules/global/layouts/__index';

// import our local modules
@import '../modules/local/typography/__index';


//==========  Begin styles  ==========//

// Helper class
.hidden {
	display: none;
}


// Reset
.cms-toolbar {
	@extend .hidden;
}


.mobile-ui {
	@extend .hidden;
}


// Print styles
* {
	color: $color-black;
	background: none;
}

.wrapper {
	padding-bottom: 0 !important;
	padding: 2rem;
}

.header {

	.menu-toggle,
	.nav {
		@extend .hidden;
	}

	.inner {

		* { 
			@extend .hidden; 
		}

		.company-logo-link,
		.company-logo {
			display: block;
		}

		.company-logo {
			margin-bottom: 3rem;
		}
	}
}

.hero {

	.inner {
		
		* { 
			@extend .hidden; 
		}
		
		.profile-image-wrapper,
		.profile-image {
			display: block;
		}

		.profile-image-wrapper {
			margin-bottom: 3rem;
		}
	}
}

.column {

	&.right,
	&.left {
		@extend .hidden;
	}
}

.bx-controls,
.staff-rotator-container {
	@extend .hidden;
}

.pre-footer {
	@extend .hidden;
}

.search-container {
	@extend .hidden;
}

.testimonial-list,
.office-list,
.social-icons,
.footer-block ul {
	@extend .hidden;
}

.cookies {
	@extend .hidden;
}

.type-profile {

	.hero {
		.profile-image {
			display: block;
			background: none !important;

			img {
				display: block;
			}
		}
	}

	.staff-biography {
		.h1 {
			margin: ($paragraph-margin) 0 0;
		}

		.job-title {
			margin-bottom: ($paragraph-margin)
		}
	}
}
