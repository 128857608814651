
//==========  Borders  ==========//

// build our default border style 

$border-size: 1px;
$border-style: solid;
$border-color: #c7d7e5;

// combine border variables into a default
// this is used throughout our modules as a base
$border: $border-size $border-style $border-color;
$border-light: $border-size $border-style lighten($border-color, 3%);

// set a default border-radius
// this is used in our forms and search styling
$border-radius: 3px;
