html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.hidden, .cms-toolbar, .mobile-ui, .header .menu-toggle,
.header .nav, .header .inner *, .hero .inner *, .column.right, .column.left, .bx-controls,
.staff-rotator-container, .pre-footer, .search-container, .testimonial-list,
.office-list,
.social-icons,
.footer-block ul, .cookies {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

.block-text ul,
.biography ul, .block-text ol,
.biography ol {
  padding: 0 0 0 20px;
  margin: 0 0 2rem; }

.block-text ul,
.biography ul {
  list-style: disc; }

.block-text ol,
.biography ol {
  list-style: decimal; }

ul, ol, .block-text ul.search-tabs, .block-text ul.search-results,
.biography ul.search-tabs,
.biography ul.search-results, .block-text .button-list {
  list-style: none;
  padding: 0;
  margin: 0; }

.button, .block-text .button-list li a {
  border: none;
  border-radius: 3px;
  background: #233582;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease-out; }

.button:hover, .block-text .button-list li a:hover {
  background: #34a4b5;
  color: #fff; }

body {
  color: #636c74;
  font-family: "Catamaran", "Helvetica Neue", "Arial", sans-serif;
  font-size: 1.2rem;
  font-weight: 300; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 2rem;
  color: #233582;
  font-family: "Catamaran", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5; }

h1, .h1 {
  font-size: 2.3rem;
  letter-spacing: -1px;
  font-weight: 700; }

h2, .h2 {
  font-size: 1.9rem;
  letter-spacing: -1px; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.3rem; }

h5, .h5 {
  font-size: 1.2rem; }

h6, .h6 {
  font-size: 1.1rem; }

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #4f7297; }

p {
  margin: 0; }

strong {
  font-weight: 700; }

hr {
  border: 1px solid #c7d7e5; }

img {
  display: block;
  max-width: 100%; }

blockquote {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7;
  color: #728eac; }
  blockquote > p:before {
    content: "\201C  "; }
  blockquote > p:after {
    content: " \201D"; }
  blockquote + p {
    margin: 1rem 0;
    color: #233582; }

iframe {
  border: none; }

a {
  color: #233582;
  text-decoration: none;
  transition: all 0.2s ease-out; }
  a:hover {
    color: #34a4b5; }

.table-scroll {
  overflow: scroll;
  margin: 0 0 2rem; }
  .table-scroll table {
    width: 100%; }
    .table-scroll table th {
      padding: 1.33333rem 2rem;
      border: 2px solid #1e2d6e;
      background: #233582;
      color: #fff;
      font-family: "Catamaran", "Helvetica Neue", "Arial", sans-serif; }
    .table-scroll table td {
      padding: 2rem;
      border: 2px solid #dce1e5;
      vertical-align: top; }
    .table-scroll table p {
      margin: 0; }

.block-text,
.biography {
  line-height: 1.9; }
  .block-text p,
  .biography p {
    margin: 0 0 2rem; }
    .block-text p a,
    .biography p a {
      color: #34a4b5; }
      .block-text p a:hover,
      .biography p a:hover {
        text-decoration: underline; }
    .block-text p.intro,
    .biography p.intro {
      padding: 0 0 2rem;
      border-bottom: 1px solid #c7d7e5;
      color: #636c74;
      font-family: "Catamaran", "Helvetica Neue", "Arial", sans-serif;
      font-size: 1.3rem; }
      @media only screen and (min-width: 40em) {
        .block-text p.intro,
        .biography p.intro {
          font-size: 1.5rem; } }
  .block-text ul.search-tabs li,
  .biography ul.search-tabs li {
    margin-bottom: 0; }
  .block-text ul li, .block-text ol li,
  .biography ul li,
  .biography ol li {
    margin-bottom: 0.66667rem; }
    .block-text ul li:last-child, .block-text ol li:last-child,
    .biography ul li:last-child,
    .biography ol li:last-child {
      margin-bottom: 0; }

.info-text {
  font-size: 1rem; }
  .info-text ul li {
    margin-bottom: 0; }
  .info-text ul ul {
    margin: 0; }

.block-text .button-list {
  margin-bottom: 2rem; }
  .block-text .button-list li {
    display: inline-block;
    margin-right: 2rem; }
    .block-text .button-list li a {
      font-weight: 700; }

.button, .block-text .button-list li a {
  display: inline-block;
  padding: 0.66667rem 2.8rem;
  cursor: pointer; }
  .button a, .block-text .button-list li a a {
    color: inherit;
    transition: inherit; }

.hidden, .cms-toolbar, .mobile-ui, .header .menu-toggle,
.header .nav, .header .inner *, .hero .inner *, .column.right, .column.left, .bx-controls,
.staff-rotator-container, .pre-footer, .search-container, .testimonial-list,
.office-list,
.social-icons,
.footer-block ul, .cookies {
  display: none; }

* {
  color: #111;
  background: none; }

.wrapper {
  padding-bottom: 0 !important;
  padding: 2rem; }

.header .inner .company-logo-link,
.header .inner .company-logo {
  display: block; }

.header .inner .company-logo {
  margin-bottom: 3rem; }

.hero .inner .profile-image-wrapper,
.hero .inner .profile-image {
  display: block; }

.hero .inner .profile-image-wrapper {
  margin-bottom: 3rem; }

.type-profile .hero .profile-image {
  display: block;
  background: none !important; }
  .type-profile .hero .profile-image img {
    display: block; }

.type-profile .staff-biography .h1 {
  margin: 2rem 0 0; }

.type-profile .staff-biography .job-title {
  margin-bottom: 2rem; }
