
//==========  Site structure  ==========//

// the max-width the site will be
$max-width: 1300px;

// the padding around the body on devices
// only used up to the max-width viewport
$site-padding: 1.5rem;

// height of the cms admin bar
$admin-bar: 30px;

// set the aspect ratio of any videos
$player: (100% / 16) * 9;


//==========  Blocks layout  ==========//

// used throughout our modules
// keep spacing between elements consistent

$block-padding: 2rem;
$block-margin: 3rem;

//==========  Services  ==========//

$services: (
	// for you
	family-law-solicitors,
	conveyancing-solicitors,
	wills-trusts-and-probate,
	inheritance-disputes-solicitors,
	litigation-and-dispute,
	criminal-solicitors,
	employment-law-employees,
	
	// For your Business
	commercial-property-solicitors,
	corporate-solicitors,
	commercial-solicitors,
	dispute-resolution-solicitors,
	employment-law-employers,
	construction-and-engineering,
	fdr-hr,
	hr,
	notary-services-solicitors,
	notarial-services,
	medical-negligence-solicitors,
	clinical-negligence
)
