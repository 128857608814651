
.block-text .button-list {
    @extend %reset-ul;
    margin-bottom: $paragraph-margin;

    li {
        display: inline-block;
        margin-right: ($block-margin * 2/3);

        a {
            @extend .button;
            font-weight: $font-bold;
        }

    }

}

// Default .button class
.button {
    @extend %button;
    display: inline-block;
    padding: ($block-padding * 1/3) ($block-padding * 1.4);
    cursor: pointer;

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}
