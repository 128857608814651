
// Clear paragraph styles
p {
	margin: 0;
}

// Reset strong tags
strong {
	font-weight: $font-bold;
}

// Reset hr tags 
hr {
	border: $border;
}

img {
	display: block;
	max-width: 100%;
}

blockquote {
	margin: 0; 
	padding: 0;
	font-size: $h3;
	font-weight: $font-light;
	line-height: 1.7;
	color: mix($color-white, $color-darkgrey, 20%);

	> p {
		&:before {
			content: "\201C  ";
		}

		&:after {
			content: " \201D";
		}
	}

	+ p {
		margin: ($block-margin * 1/3) 0;
		color: $color-primary;
	}
}

iframe {
	border: none;
}
